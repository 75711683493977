import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'

class Footer extends React.Component{

    render() {
        const FooterMain = styled.div`
            background-color: #111;
            height: 65px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            @media (max-width: 500px) {
              height: 110px;
            }
        `

        const SocialIcons = styled.div`
            .social_icon {
                font-size: 15px;
                color: #555;
                margin: 0 5px;
                cursor: pointer;
                transition: .5s;
                &:hover {
                    color: #3dc7f5;
                }
            }
        `
        
        const CopyRight = styled.div`
            font-size: 12px;
            color: #888484;
            margin: 0 20px;
            transition: .5s;
        `
         
        
        return (
            <FooterMain>
                <SocialIcons>
                    <FontAwesomeIcon icon={faGithub} className="social_icon" onClick={() => window.open('https://www.victrom.com')}/>
                    <FontAwesomeIcon icon={faTwitter} className="social_icon" onClick={() => window.open('https://www.twitter.com')} />
                      <FontAwesomeIcon icon={faYoutube} className="social_icon" onClick={() => window.open('https://www.instagram.com/victrom1/')} />
                    <FontAwesomeIcon icon={faLinkedin} className="social_icon" onClick={() => window.open('https://www.linkedin.com')} />
                   
                     <Row>
                     <CopyRight>
                        Copyright 2020 | All Rights Reserved by Victrom Integrated Computing Solutions W.L.L. - Kuwait | Chamber of Commerce Membership Number 428290
                </CopyRight>
                    </Row>
                </SocialIcons>
               
                 
                 
            </FooterMain>
           
        )
    }
}
export default Footer